<template>
  <div>
    <!--    tw-min-h-screen-->
    <div class=" tw-flex  tw-justify-center tw-sm:py-12 ">
      <div class="tw-relative  tw-sm:max-w-xl tw-sm:mx-auto">
        <div class="tw-relative tw-px-4 tw-shadow-lg tw-sm:p-20">
          <div class=" tw-mx-auto tw-max-w-7xl" style="min-width:50vw">

            <div class="tw-px-6 tw-pb-8 tw-py-5">
              <div v-show="tab === 'welcome'">
                <WelcomeMessage/>
              </div>

              <div v-show="tab === 'address_information'">
                <AddressInformationComponent ref="AddressInformationComponentREF"
                                             :data="portalInitialData"
                                             @validTab=""/>
              </div>

              <div class=" tw-left-0 tw-right-0 tw-py-5 tw-bg-white ">
                <div class="tw-mx-auto ">
                  <div class="tw-flex tw-justify-between">
                    <div class="tw-w-1/2">
                      <button
                        v-if="tab !== 'welcome'"
                        @click="backTab()"
                        class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2">
                        <svg-icon name="solid/chevron-left"/>
                        Back
                      </button>
                    </div>

                    <div class="tw-w-1/2 tw-text-right">
                      <button
                        v-if="tab !== 'address_information'"
                        @click="nextTab()"
                        class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2">
                        Continue
                        <svg-icon name="solid/chevron-right"/>
                      </button>

                      <button
                        v-if="tab === 'address_information'"
                        @click="submitFormData()"
                        class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2">
                        <svg-icon name="solid/floppy-disk"/>
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bottom Navigation -->

            <!--            <WelcomeMessage/>-->
            <!--            <form-wizard
                          back-button-text="Back"
                          next-button-text="Continue"
                          step-size="xs"
                          subtitle=""
                          title=""
                          @on-complete="submitFormData()"
                        >
                          <tab-content title="''">
                            <WelcomeMessage/>
                          </tab-content>
                          <tab-content
                            :before-change="()=> $refs.AddressInformationComponentREF.isAValidTab()"
                            title="''"
                          >
                            <AddressInformationComponent ref="AddressInformationComponentREF"
                                                         :data="portalInitialData"/>
                          </tab-content>
                          <tab-content title="''">
                            <ContactDetailsComponent ref="ContactDetailsComponentREF"
                                                     :contactTypes="contactTypes"
                                                     :data="portalInitialData"
                                                     :roles="roles"/>
                          </tab-content>

                          <button
                            slot="finish"
                            class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2">
                            <svg-icon name="solid/floppy-disk"/>
                            Finish
                          </button>

                          <button
                            slot="prev"
                            class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2">
                            <svg-icon name="solid/chevron-left"/>
                            Back
                          </button>

                          <button
                            slot="next"
                            class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2">
                            Continue
                            <svg-icon name="solid/chevron-right"/>
                          </button>


                        </form-wizard>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import GlobalMixins from '@/Mixins/GlobalMixins.js'
import SignUpWizardEndPoints from "../../routes/SignUpWizardEndPoints";

import AddressInformationComponent from './FormWizardComponents/AddressInformationComponent.vue'
import ContactDetailsComponent from './FormWizardComponents/ContactDetailsComponent.vue'

import WelcomeMessage from '@/views/WelcomeToOrderingPortal/FormWizardComponents/WelcomeMessage.vue'
import {mapGetters} from "vuex";

export default {
  name: "WelcomeToOrderingPortalIndex",
  mixins: [GlobalMixins],
  data() {
    return {
      portalInitialData: {},
      contactTypes: [],
      roles: [],
      tab: 'welcome'
    }
  },
  components: {
    AddressInformationComponent,
    ContactDetailsComponent,
    WelcomeMessage
  },
  computed: {
    ...mapGetters({
      allow_multiple_user: 'users/multipleAccount'
    })
  },
  methods: {
    async nextTab() {
      if (this.tab === 'welcome') {
        this.tab = 'address_information'
      } else if (this.tab === 'address_information') {
        this.$refs.AddressInformationComponentREF.isAValidTab()
      }
    },
    async backTab() {
      if (this.tab === 'address_information') {
        this.tab = 'welcome'
      } else if (this.tab === 'contacts') {
        this.tab = 'address_information'
      }
    },
    async submitFormData() {
      let address = this.$refs.AddressInformationComponentREF.getAddressInformation()

      let payload = {
        shipping_address: address.addressInformation.shippingAddress,
        is_shipping_address_same_as_main_address: address.isShippingAddressSameAsMainAddress,
        billing_address: address.addressInformation.billingAddress,
        is_billing_address_same_as_main_address: address.isBillingAddressSameAsMainAddress,
        contacts: []//this.$refs.ContactDetailsComponentREF.getAddedContacts()
      }
      this.startLoader()
      try {
        (await SignUpWizardEndPoints.savePortalInitialDataSetup(payload))
        await this.$store.commit('users/setSetUpCurrentState', true);
        this.$router.push({name: 'dashboard'}).catch(err => err);
      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
      this.stopLoader()

    }
  },
  async mounted() {

    this.startLoader()

    try {
      this.portalInitialData = (await SignUpWizardEndPoints.getPortalInitialDataSetup()).data.data
      this.contactTypes = (await SignUpWizardEndPoints.activeContactTypes()).data.data
      this.roles = this.allow_multiple_user ?  (await SignUpWizardEndPoints.getRoles()).data.data : []
    } catch (error) {
      this.showErrorModal(error)
      this.stopLoader()
    }
    this.stopLoader()

  }
}
</script>

