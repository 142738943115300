<template>
  <div>
    <div class="tw-text-center tw-font-bold tw-text-2xl">
      CONTACT DETAILS
    </div>


    <div class="tw-divide-y tw-divide-gray-200">
      <div
        class="tw-py-8 tw-text-base tw-leading-6 tw-space-y-4 tw-text-gray-700 tw-sm:text-lg tw-sm:leading-7"
      >
        <div class="tw-flex-1">
          <div class="tw-font-bold tw-text-xl">
            Primary contact <span class="tw-text-sm tw-font-light">(this is you)</span>
          </div>
          <ul
            v-if="data.primary_contact"
            class="tw-space-y-1 tw-pt-1 tw-font-light"
          >
            <li>
              <svg-icon name="solid/user"/>
              {{ data.primary_contact.first_name }} {{ data.primary_contact.last_name }}
            </li>
            <li>
              <svg-icon name="solid/envelope"/>
              {{ data.primary_contact.email || "N/A" }}
            </li>
            <li>
              <svg-icon name="solid/phone"/>
              {{ data.primary_contact.phone || "N/A" }}
            </li>
          </ul>
        </div>

        <p>
          In this section you can provide additional contact details and also invite other users:
        </p>


        <div class="tw-grid md:tw-grid-cols-3 tw-gap-5">
          <SingleContactTileComponent
            v-for="(contact,index) in contacts"
            :key="index"
            :contact="contact"
            :contact-types="contactTypes"
            :index="index"
            :roles="roles"
            @removeThisContact="(itemIndexToBeRemoved) => contacts.splice(itemIndexToBeRemoved, 1)"
            @updateThisContact="(itemIndexToBeUpdated) => $refs.updateContactModal.open(contacts[itemIndexToBeUpdated],itemIndexToBeUpdated)"
          />
          <CreateNewCardComponent
            :title="'add new contact'"
            @createNewModalOpen="$refs.createNewContactModalREF.open()"
          />
        </div>
        <div class="row ">
          <CreateNewContactModal
            ref="createNewContactModalREF"
            :added-contacts="contacts"
            :contact-types="contactTypes"
            :roles="roles"
            @newContact="(dataReceived) => contacts.push(dataReceived)"
            :isCreateUserAllowed="allow_multiple_user"
          />

          <UpdateContactModal
            ref="updateContactModal"
            :added-contacts="contacts"
            :contact-types="contactTypes"
            :roles="roles"
            @updatedContact="(dataReceived) => contacts.splice(contacts.indexOf(dataReceived.index), 1, dataReceived)"
            :isCreateUserAllowed="allow_multiple_user"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CreateNewCardComponent from '/src/components/commons/CreateNewCardComponent'
import CreateNewContactModal
  from "/src/views/WelcomeToOrderingPortal/FormWizardComponents/Modals/NewContactModal";
import UpdateContactModal
  from "/src/views/WelcomeToOrderingPortal/FormWizardComponents/Modals/UpdateContactModal";
import SingleContactTileComponent
  from '/src/views/WelcomeToOrderingPortal/FormWizardComponents/SingleContactTileComponent.vue'
import {mapGetters} from "vuex";

export default {
  name: "AddressInformationPartial",
  components: {
    CreateNewCardComponent,
    CreateNewContactModal,
    SingleContactTileComponent,
    UpdateContactModal
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    contactTypes: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      contacts: []
    }
  },
  computed: {
    ...mapGetters({
      allow_multiple_user: 'users/multipleAccount'
    })
  },
  methods: {
    getAddedContacts() {
      return this.contacts.map((contact) => {
        contact.roles = contact.added_roles
        return contact
      })
    }
  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
