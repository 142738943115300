<template>
  <base-modal
    v-model:show="createNewContactModalVisibility"
    :title="'New contact'"
  >

    <template v-slot:modal-content>
      <span class="text text_italic required__text">*Required Fields</span>
      <div class="row">
        <Form
          v-slot="{ errors }"
          id="new_contact"
          autocomplete="off"
          class="form form_col"
          @submit="handleValidationFromBackend"
        >
          <div class="col-12">

            <label class="form__label">
              <span class="form__labelTitle">First Name*</span>
              <Field v-model="newContact.first_name" :rules="{required:true, max:255}"
                     class="form__input" label="first name"
                     name="first_name"
                     placeholder="First Name*"
                     type="text"
              />
              <span class="form__error">{{ errors.first_name }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">Last Name*</span>
              <Field v-model="newContact.last_name" :rules="{required:true, max:255}" class="form__input"
                     label="last name"
                     name="last_name"
                     placeholder="Last Name*"
                     type="text"
              />
              <span class="form__error">{{ errors.last_name }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">E-mail</span>
              <Field v-model="newContact.email" :rules="{required:newContact.create_user,email: true}"
                     class="form__input"
                     label="e-mail"
                     name="email"
                     placeholder="E-mail"
                     type="text"
              />

              <span class="form__error">{{ errors.email }}</span>
            </label>
            <label class="form__label">
              <span class="form__labelTitle">Landline Number*</span>
              <Field v-model="newContact.phone"
                        :rules="{required: true, digits_without_whitespace: 10, min: 6}"
                        class="form__input"
                        label="landline number"
                        v-maska="'## ## ### ###'"
                        name="phone"
                        placeholder="Landline Number*"
              />
              <span class="form__error">{{ errors.phone }}</span>
            </label>

            <label class="form__label">
              <span class="form__labelTitle">Mobile number</span>
              <Field v-model="newContact.mobile"
                        :rules="{ digits_without_whitespace: 10}"
                        class="form__input"
                        label="mobile number"
                        v-maska="'## ## ### ###'"
                        name="mobile"
                        placeholder="Mobile Number"
              />
              <span class="form__error">{{ errors.mobile }}</span>
            </label>

            <label class="form__label mt-0">
              <span class="form__labelTitle">Type*</span>
              <Field as="select" v-model="newContact.contact_type_id"
                      :rules="{required:true}"
                      class="form__input form__select"
                      name="type"
                      placeholder="Type*"
              >
                <option  :value="null" disabled >Type*</option>
                <option
                  v-for="(item, index) in contactTypes"
                  :key="index"
                  :value="item.id"
                >{{ item.name }}
                </option>
              </Field>
              <span class="form__error">{{ errors.type }}</span>
            </label>


            <label v-if="isCreateUserAllowed" class="form__label form__label_row tw-text-sm tw-mb-4">

              <input
                v-model="newContact.create_user"
                class="form__checkbox"
                hidden
                name="roles"
                type="checkbox"
              />

              <span class="form__checkbox_icon tw-h-4 tw-w-4"></span>
              <span class="form__labelTitle tw-text-xl">Create user</span>
            </label>

            <transition name="fade">
              <div v-if="isCreateUserAllowed && newContact.create_user" class="tw-mb-3">
                <div class="form__labelTitle tw-mb-2">USER ROLES</div>
                <ul
                  class="
              list
              text_left
              tw-text-sm
              mx-auto
              tw-grid tw-grid-cols-1
              md:tw-grid-cols-2
              tw-gap-2
            "
                >
                  <li
                    v-for="role in roles"
                    :key="role.id"
                    class="item tw-space-x-1.5"
                  >
                    <label class="form__label form__label_row tw-text-sm">
                      <Field
                        :id="`role_${role.id}`"
                        v-model="newContact.roles"
                        :rules="{required: newContact.create_user,minLength:1}"
                        :value="role.id"
                        class="form__checkbox"
                        hidden
                        name="roles"
                        type="checkbox"
                      />

                      <span class="form__checkbox_icon tw-h-4 tw-w-4"></span>
                      <span class="form__labelTitle"> {{ role.name }}</span>
                    </label>
                  </li>
                </ul>
                <div v-if="errors.roles" class="tw-mt-1 tw-pb-4">
                  <span class="form__error ">{{ errors.roles }}</span>
                </div>

              </div>
            </transition>
          </div>

        </Form>
      </div>
    </template>
    <template v-slot:actions>
      <button class="btn btn_transparent mx-0 mb-0" form="new_contact">
        <svg-icon name="solid/floppy-disk"/>
        Add
      </button>

    </template>
  </base-modal>
</template>

<script>
import SignUpWizardEndPoints from "@/routes/SignUpWizardEndPoints";
import GlobalMixins from "@/Mixins/GlobalMixins";
import { maska } from 'maska'

export default {
  name: "NewContactModal",
  directives: {
    maska
  },
  mixins : [GlobalMixins],
  props: {
    contactTypes: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    addedContacts: {
      type: Array,
    },
    isCreateUserAllowed : {
      type: Boolean,
    }
  },
  data() {
    return {
      createNewContactModalVisibility: false,
      newContact: {
        roles: [],
        create_user: false
      }
    }
  },
  methods: {
    async open() {
      this.newContact = {
        roles: [],
        create_user: false
      }
      this.createNewContactModalVisibility = true
    },
    async handleValidationFromBackend() {

     /* this.$validator.validate().then(async valid => {
        if (valid) {
*/
          try {

            let doesThisEmailExist = _.find(this.addedContacts, {email: this.newContact.email})
            let doesThisPhoneExist = _.find(this.addedContacts, {phone: this.newContact.phone})
            let doesThisMobileExist = this.newContact.mobile && _.find(this.addedContacts, {mobile: this.newContact.mobile})

            if (doesThisEmailExist || doesThisPhoneExist || doesThisMobileExist) {
              if (doesThisEmailExist) {
               /* this.$validator.errors.add({
                  field: 'email',
                  msg: 'This email already exists in one of your contacts.'
                });*/
              }
              if (doesThisPhoneExist) {
               /* this.$validator.errors.add({
                  field: 'phone',
                  msg: 'This phone already exists in one of your contacts.'
                });*/
              }
              if (doesThisMobileExist) {
              /*  this.$validator.errors.add({
                  field: 'mobile',
                  msg: 'This mobile already exists in one of your contacts.'
                });*/
              }
              return
            }

            this.startLoader()
            await SignUpWizardEndPoints.validateContact(this.newContact)

            this.newContact.added_roles = this.newContact.roles.map((x) => x)
            this.$emit('newContact', this.newContact);
            this.createNewContactModalVisibility = false

          } catch (errors) {

            if (errors.response.status === 422) {
              _.forEach(errors.response.data.errors, (error, index) => {
              /*  this.$validator.errors.add({
                  field: index,
                  msg: error[0]
                });*/
              })
            }
            this.showErrorModal(errors)
            this.stopLoader()
          }
      /*  }*/
        this.stopLoader()
    /*  });*/
    },
  }
}
</script>

<style scoped>

</style>
