import {httpReq} from "@/htttpReq";

const routePrefix = '/ordering-portal'

export default {

  //Core
  activeContactTypes: () => httpReq.get('/core/contact-types'),

  //Ordering portal
  getPortalInitialDataSetup: () => httpReq.get(`${routePrefix}/portal-initial-data-setup`),
  savePortalInitialDataSetup: (payload) => httpReq.post(`${routePrefix}/portal-initial-data-setup`,payload),
  validateContact: (payload) => httpReq.post(`${routePrefix}/validate-contact`, payload),
  getRoles: () => httpReq.get(`${routePrefix}/roles`),

}
