<template>
  <div>
    <Form
      v-slot="{ errors }"
      autocomplete="off"
      @submit="formValid"
    >

      <label class="form__label " v-if="validApiKey" :key="address?.id">
        <span class="form__labelTitle">Address*</span>
        <GMapAutocomplete
          :id="randId"
          :key="key"
          ref="address"
          :options="{ componentRestrictions: { country: 'aus' },fields: ['address_components']}"
          :value="address.address"
          class="form__input"
          placeholder="Search address"
          @click="checkIsInputDisabled( $event.target)"
          @keyup="checkIsInputDisabled( $event.target)"
          @change="setFullAddress"
          @place_changed="setFullAddress"
        >
        </GMapAutocomplete>

        <span v-if="showAddressError && !address.address" class="form__error">The address field is required</span>
      </label>

      <div  v-else>
        <label class="form__label ">
          <span class="form__labelTitle">Address*</span>
          <Field
            :key="key"
            v-model="address.address"
            :rules="{required: true}"
            class="form__input"
            name="address"
            placeholder="Address"
            type="text"
          />
          <span class="form__error">{{ errors.address }}</span>
        </label>
      </div>

      <div v-if="suburbAndPostCodeInSameRaw" class="row">
        <div class="col-6">
          <label class="form__label ">
            <span class="form__labelTitle">Suburb*</span>
            <Field
              v-model="address.suburb"
              :rules="{required: true,max: 128}"
              class="form__input"
              name="suburb"
              placeholder="Suburb"
              type="text"
            />
            <span class="form__error">{{ errors.suburb }}</span>
          </label>
        </div>
        <div class="col-6">
          <label class="form__label ">
            <span class="form__labelTitle">Postcode*</span>
            <Field
              v-model="address.postcode"
              v-maska="'####'"
              :rules="{required: true,digits: 4}"
              class="form__input"
              name="postcode"
              placeholder="Postcode"
              type="text"
            />
            <span class="form__error">{{ errors.postcode }}</span>
          </label>

        </div>
      </div>
      <div v-else>
        <label class="form__label ">
          <span class="form__labelTitle">Suburb*</span>
          <Field
            v-model="address.suburb"
            :rules="{required: true,max: 128}"
            class="form__input"
            name="suburb"
            placeholder="Suburb"
            type="text"
          />
          <span class="form__error">{{ errors.suburb }}</span>
        </label>


        <label class="form__label ">
          <span class="form__labelTitle">Postcode*</span>
          <Field
            v-model="address.postcode"
            v-maska="'####'"
            :rules="{required: true,digits: 4}"
            class="form__input"
            name="postcode"
            placeholder="Postcode"
            type="text"
          />
          <span class="form__error">{{ errors.postcode }}</span>
        </label>
      </div>

      <label class="form__label ">
        <span class="form__labelTitle">State*</span>
        <Field
          v-model="address.state"
          :rules="{required: true}"
          as="select"
          class="form__input form__select"
          name="state"
        >
          <option
            disabled
            hidden
            selected
            value=""
          >Select a state
          </option>
          <option
            v-for="(item, index) in state"
            :key="index"
            :value="item.state_id"
          >{{ item.name }}
          </option>
        </Field>
        <span class="form__error">{{ errors.state }}</span>
      </label>
      <button ref="address_create" class="d-none" type="submit"/>
    </Form>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {maska} from 'maska'
import store from "../../store/store";
import axios from 'axios';

export default {
  name: "BaseSingleAddressComponent",
  directives: {
    maska
  },
  data() {
    return {
      validApiKey: false,
      key: 0,
      valid: false,
      showAddressError: false,
      plainAddress: '',
      hasGoogleMapErrors : false,
      randId : `auto_add_${Math.round(Date.now()/1000)}`
    }
  },
  props: {
    address: {
      required: true,
      type: Object
    },
    suburbAndPostCodeInSameRaw: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
  },
  methods: {
    validateApiKey() {
      store.commit('preloader/preloader', true);
      const apiKey = process.env.VUE_APP_GOOGLE_API_KEY

      axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=New+York&key=${apiKey}`)
          .then(response => {
              if ( response.data?.error_message) {
                  console.log('API Key is not valid');
                  this.validApiKey = false
              } else {
                  console.log('API Key is valid');
                  this.validApiKey = true
              }
              this.key = Date.now()
            store.commit('preloader/preloader', false);
          })
          .catch(error => {
              console.error('Error occurred while checking API key validity:', error);
              this.validApiKey = false
              store.commit('preloader/preloader', false);

          });
    },
    checkIsInputDisabled(element) {
      setTimeout(() => {
        if (element.disabled) {
          element.style.removeProperty('background-image');
          element.placeholder = 'Start typing'
          element.disabled = false
        //  element.focus()
          this.hasGoogleMapErrors = true
        }
      }, 200)
    },
    setFullAddress(selectedAddress) {

      let mainAddress = ''
      let addressFullComponent = selectedAddress.address_components

      if (!addressFullComponent) {
        this.address.address = event.target.value
        return
      }
      let subpremise = _.find(addressFullComponent, {types: ["subpremise"]})

      let street_number = _.find(addressFullComponent, {types: ["street_number"]})
      let route = _.find(addressFullComponent, {types: ["route"]})
      let suburb = _.find(addressFullComponent, {types: ['locality', 'political']})
      let postal_code = _.find(addressFullComponent, {types: ["postal_code"]})
      let state = _.find(addressFullComponent, {types: ['administrative_area_level_1', 'political']})

      if (subpremise) mainAddress += `${subpremise.long_name}/`
      if (street_number) mainAddress += `${street_number.long_name},`
      if (route) mainAddress += `${route.long_name}`
      if (suburb) this.address.suburb = suburb.long_name
      if (postal_code) this.address.postcode = postal_code.long_name;
      if (state) this.address.state = _.find(this.state, {name: state.long_name}).state_id


      this.address.address = mainAddress
      this.plainAddress = mainAddress
      this.showAddressError = false
      this.key++

    },

    async validateForm() {
      this.$refs.address_create.click()
      this.showAddressError = typeof this.address.address == 'undefined' || this.plainAddress === ''
      setTimeout(() => {
        this.$emit('isValidAddress', this.valid)
      }, 300)

    },
    formValid() {
      this.showAddressError = typeof this.address.address == 'undefined' || this.plainAddress === ''
      if (!this.showAddressError) {
        this.valid = true
        this.$emit('isValidAddress', this.valid)
      }
    },
    updateValidationErrors(error, index) {
      let field = String(index).replace('contact_address.', '')
      /* this.$validator.errors.add({
         field: field,
         msg: error[0]
       });*/
    }
  },
  watch: {
  /*  address() {
      setTimeout(() => {
        this.key++
      }, 500)
    }*/
  },
  mounted() {
    store.commit('preloader/preloader', true);
    if (!this.state) {
      this.$store.dispatch('registration/getState');
    }

    this.plainAddress = this.address.address
    store.commit('preloader/preloader', false);

    // setTimeout(() => {
    //   if(this.hasGoogleMapErrors) {
    //     store.commit('preloader/preloader', false);
    //     return
    //   }
    //   document.getElementById(`${this.randId}`).style.opacity = 0
    //   document.getElementById(`${this.randId}`).value = 'Start typing'

    //   setTimeout(() => {
    //     let element = document.getElementById(`${this.randId}`)
    //     element.focus()
    //     setTimeout(async () => {
    //       let element = document.getElementById(`${this.randId}`)
    //       if (element) {
    //         element.style.opacity = ''
    //         this.hasGoogleMapErrors = document.getElementById(`${this.randId}`).disabled
    //         if (!this.hasGoogleMapErrors) document.getElementById(`${this.randId}`).value = ""
    //       }
    //       await window.scrollTo({top: 0, behavior: 'smooth'});
    //       store.commit('preloader/preloader', false);
    //     }, 1500)

    //   }, 200)

    // },200)


    // setInterval(() => {
    //   let element = document.getElementById(`${this.randId}`)
    //   const content = document.body.textContent || document.body.innerText;
    //   const hasText = content.indexOf("This page can't load Google Maps correctly.") !== -1;

    //   if (element || hasText) this.hasGoogleMapErrors = hasText || document.getElementById(`${this.randId}`).disabled

    // }, 100)
    this.validateApiKey()
  },
}
</script>

