<template>
  <div class="tw-card tw-text-sm">
    <div class="tw-card-head tw-bg-secondary tw-py-1.5 tw-text-white">
      <div class="tw-flex tw-items-baseline tw-justify-between">
        <div>
          <h4 class="tw-uppercase tw-py-1">
            {{ contact.first_name }} {{ contact.last_name }}
          </h4>
        </div>
      </div>
    </div>

    <div class="tw-card-body tw-text-secondary">
      <div class="tw-flex-1">
        <h5 class=" tw-font-bold">
          {{ getTypeName(contact) }} contact
        </h5>

        <ul class="tw-space-y-1 tw-pt-1">
          <li>
            <svg-icon name="solid/envelope" />
            Email: {{ contact.email || "N/A" }}
          </li>

          <li>
            <svg-icon name="solid/phone" />
            Phone: {{ contact.phone || "N/A" }}
          </li>

          <li>
            <svg-icon name="solid/mobile" />
            Phone: {{ contact.mobile || "N/A" }}
          </li>

          <li v-if="contact.create_user">
            <svg-icon name="solid/user-gear" />
            Roles:
            <div class="tw-mt-2 tw-gap-2 tw-flex tw-flex-wrap">
              <span
                v-for="roleId in contact.added_roles"
                :key="roleId"
                class="
                  tw-inline-flex
                  tw-items-center
                  tw-space-x-1
                  tw-uppercase
                  tw-font-xs
                  tw-bg-gray-200
                  tw-text-gray-600
                  tw-text-xs
                  tw-tracking-wide
                  tw-px-2
                  tw-py-1
                  tw-rounded-md
                "
              >
                <svg-icon name="solid/check-circle" />
                <span v-text="getRoleNameById(roleId)" />
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="tw-grid tw-gap-4 tw-mt-2 tw-flex tw-justify-between tw-grid-cols-2 ">
        <button
          class="btn btn_default tw-m-0 tw-w-full "
          @click="$emit('updateThisContact',index)"
        >
          <svg-icon name="solid/pencil" />
          EDIT
        </button>

        <button
          class="btn btn_default tw-m-0 tw-w-full"
          @click="$emit('removeThisContact',index)"
        >
          <svg-icon name="solid/times" />

          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "SingleContactTitleComponent",
  props: {
    contact: {
      required: true,
      type: Object
    },
    contactTypes: {
      required: true,
      type: Array
    },
    roles: {
      required: true,
      type: Array
    },
    index: {
      required: true,
      type: Number
    }
  },
  methods: {
    getTypeName(contact) {
      return _.find(this.contactTypes, {id: contact.contact_type_id}).name
    },
    getRoleNameById(roleId) {
      return _.find(this.roles, {id: roleId}).name
    }
  }

}
</script>

