<template>
  <div>
    <div class="tw-max-w-md tw-mx-auto tw-pt-6">
      <div class="tw-text-center tw-font-bold tw-text-xl ">
        Welcome to Ordering Portal
      </div>
      <div class="tw-divide-y tw-divide-gray-200">
        <div
          class="tw-py-8 tw-text-base tw-leading-6 tw-space-y-4 tw-text-gray-700 tw-sm:text-lg tw-sm:leading-7"
        >
          <p>
            Thank you for signing up for our Ordering Portal. We need a few more details about your
            business in order to start using the portal
          </p>
          <p>We will need to check the following:</p>
          <ul class="tw-list-disc tw-space-y-2">
            <li class="tw-flex tw-items-start">
              <span class="tw-h-6 tw-flex tw-items-center tw-sm:h-7">
                <svg
                  class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-cyan-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    clip-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
              <p class="tw-ml-2">
                Billing and shipping address
              </p>
            </li>
            <li class="tw-flex tw-items-start">
              <span class="tw-h-6 tw-flex tw-items-center tw-sm:h-7">
                <svg
                  class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-cyan-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    clip-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
              <p class="tw-ml-2">
                Contact details & Users
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeMessage"
}
</script>

<style scoped>

</style>
